<template>
    <div class="data">
        <div class="data-header">
            <span class="data-title">数据概况</span>
            <div class="data-content">
                <div class="data-item" v-for="item in severalList">
                    <span class="text">{{item.total}}</span>
                    <span class="g-num">{{item.num}}</span>
                    <div class="added-this-week" v-if="item.total=='涨粉数'|| item.total=='转发总量'">
                        <span class="text">昨日新增</span>
                        <i class="iconfont">&#xe643;</i>
                        <span class="num">{{item.addNum}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="data-trend">-->
            <!--<span class="title">点赞趋势</span>-->
            <!--<div class="content">-->
                <!--<div id="likeTrend" :style="{width: '100%', height: '442px'}"></div>-->
            <!--</div>-->
        <!--</div>-->
        <div class="data-trend">
            <span class="title">粉丝趋势</span>
            <div class="content">
                <div class="data-menu">
                    <div class="item" :class="{selected: fanTabIndex === 0}" @click="toggleFanTab(0)">近7天</div>
                    <div class="item" :class="{selected: fanTabIndex === 1}" @click="toggleFanTab(1)">30天</div>
                </div>
                <div id="ecFanTrend" :style="{width: '100%', height: '442px'}"></div>
            </div>
        </div>
     </div>
</template>

<script>
    export default {
        name: "EcDataOverviewModule",
        data(){
            return{
                //数据概况
                mineInfo: {},
                //粉丝趋势近7天/30天切换
                fanTabIndex:0,
                //播主一周内粉丝趋势数据
                weekFansTrend:{},
                //播主一月内粉丝趋势数据
                monthFansTrend:{},
                author_id:'',
                severalList: [
                    {
                        total: "点赞总数",
                        num: 0
                    },
                    {
                        total: "评论总数",
                        num: 0
                    },
                    {
                        total: "涨粉数",
                        num: 0,
                        addNum: 0
                    },
                    {
                        total: "转发总量",
                        num: 0,
                        addNum: 0
                    }
                ],
                fansTrend:{},

            }
        },
        // props: {
        //     severalList: Array,
        //     fansTrend: Object
        // },
        mounted() {
            window.onresize = () => {
                if (this.fanTabIndex == 0) {
                    this.ecFanTrendFun(this.weekFansTrend); //粉丝趋势
                } else {
                    this.ecFanTrendFun(this.monthFansTrend); //粉丝趋势
                }
            };

            let douyinuser_id = this.$route.query.douyinuser_id;

            this.getDate(douyinuser_id);
            this.getDateFans(douyinuser_id);
        },
        methods: {

            // 获取数据概况
            getDate(douyinuser_id) {
                let param = {
                    douyinuser_id: douyinuser_id
                };
                this.$httpStudent.axiosGetBy(this.$api.sellGoodsPeopleDetail, param, res => {
                        if (res.code == 200) {
                            // console.log(res, "aaaaa");
                            //点赞总数
                            this.severalList[0]['num'] = res.data.total_favorited;
                            //评论总数
                            this.severalList[1]['num'] = res.data.total_comment;
                            //粉丝总数
                            this.severalList[2]['num'] = res.data.follower_count;
                            //	比上日增长粉丝变化量
                            this.severalList[2]['addNum'] = res.data.follower_incr;
                            //转发总数
                            this.severalList[3]['num'] = res.data.total_share;
                            //比上日增长转发变化量
                            this.severalList[3]['addNum'] = res.data.share_incr;
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    }
                );
            },
            // 获取数据概况的粉丝趋势数据
            getDateFans(author_id) {
                this.$httpStudent.axiosGetBy(this.$api.hotPeopleFansData, {author_id: author_id}, res => {
                        // console.log(res,'粉丝趋势')
                        if (res.code == 200) {
                            //一周内粉丝趋势数据
                            this.fansTrend["week"] = res.data.fans_incr.data7;
                            //一周内粉丝趋势数据
                            this.fansTrend["month"] = res.data.fans_incr.data30;
                            // this.ecFanTrendFun(this.fansTrend.week); //粉丝趋势
                            this.ecFanTrendFun(this.fansTrend.week);
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                                duration: 1000
                            });
                        }
                    }
                );
            },

            //粉丝趋势近7天/30天切换
            toggleFanTab(index) {
                // console.log(index)
                this.fanTabIndex = index;
                if (index == 0) {
                    this.ecFanTrendFun(this.fansTrend.week); //粉丝趋势
                } else {
                    this.ecFanTrendFun(this.fansTrend.month); //粉丝趋势
                }
            },
            // 粉丝趋势
            ecFanTrendFun(ecFanTrendArray) {
                let add = ecFanTrendArray.add,
                    total = ecFanTrendArray.total,
                    time = [],
                    addValue = [],
                    totalValue = [];
                for (let i in add) {
                    time.push(add[i].time_node.substring(5, add[i].time_node.length));
                    addValue.push(add[i].total_comment);
                    totalValue.push(total[i].total_comment);
                }
                // console.log(time,1111)
                this.ecFanTrend = this.$echarts.init(document.getElementById("ecFanTrend"));
                this.ecFanTrend.setOption(
                    {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985'
                                }
                            }
                        },
                        title: {
                            x: "center",
                            y: "bottom",
                            textStyle: {
                                //设置主标题风格
                                Color: "#333", //设置主标题字体颜色
                                fontSize: 14,
                                fontStyle: "normal"
                            }
                        },
                        legend: {
                            left: "left",
                            data: ["数据趋势", "数据总计"]
                        },
                        xAxis: {
                            type: "",
                            data: time,
                            //设置网格线颜色
                            boundaryGap: false,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#999"
                                }
                            }
                        },
                        grid: {
                            left: "3%",
                            right: "4%",
                            bottom: "3%",
                            containLabel: true
                        },
                        yAxis: {
                            type: "value",
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: ["#f1f1f1"]
                                }
                            },
                            //隐藏刻度线
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: "#999"
                                }
                            },
                            axisLabel: {
                                fontSize: "14",
                                marginTop: "35px",
                                formatter: "{value}W",
                                show: true
                            }
                        },
                        series: [
                            {
                                name: "数据趋势",
                                data: addValue,
                                type: "line",
                                lineStyle: {
                                    color: "#20C997",
                                    width: 3
                                },
                                areaStyle: {
                                    color: "#d8f6ec"
                                },
                                smooth: true,
                                symbol: "none"
                            },
                            {
                                name: "数据总计",
                                data: totalValue,
                                type: "line",
                                lineStyle: {
                                    color: "#FF0001",
                                    width: 3
                                },
                                areaStyle: {
                                    color: "#d8f6ec"
                                },
                                smooth: true,
                                symbol: "none"
                            }
                        ]
                    }, true);
            }
        }
    }
</script>
<style scoped lang="scss">
    .data{
        padding: 0 25px;
        height: 100%;
        /*overflow: auto;*/
        .data-header{
            .data-title{
                font-size: 14px;
                font-weight: 400;
            }
            .data-content{
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                line-height: 35px;
                .data-item{
                    display: flex;
                    flex-direction: column;
                    .g-num{
                        font-size: 24px;
                        font-weight: 700;
                        color: #42d7ab;
                    }
                    .added-this-week{
                        & i{
                            font-size: 14px;
                            color: #ffb624;
                        }
                        & .num{
                            font-size: 14px;
                            font-weight: 700;
                            color: #ffb624
                        }

                    }
                }
                .text{
                    color:#656565;
                    font-size: 14px;
                }
            }
        }
        .data-trend{
            margin-top: 20px;
        }
        .data-menu{
            display: flex;
            justify-content: flex-end;
            font-size: 14px;
            font-weight: 400;
            color: #999;
            text-align: right;
            .item {
                display: inline-block;
                padding: 0 10px;
                border-right: 1px solid #999;
                cursor: pointer;
                &:hover{
                    color: #20C997;
                }
                &:last-of-type {
                    border-right: 0;
                    padding: 0 0 0 10px;
                }
            }
            .selected{
                color: #20C997;
            }
        }
    }

</style>